<template>
    <div class="checkout container">
      <div class="header_gruop page_width flex_sb">
        <div class="icon_home flex_center" @click="toBack()"><svg t="1729563443244" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6080" id="mx_n_1729563443245" width="16" height="16"><path d="M398.912 513.152l377.28-384.256c27.904-28.416 27.904-74.432 0-102.784-27.84-28.352-73.024-28.352-100.8 0L247.808 461.504C233.792 475.776 226.88 494.464 226.944 513.152 226.88 531.84 233.792 550.464 247.808 564.736l427.584 435.392c27.84 28.352 73.024 28.352 100.8 0 27.904-28.352 27.904-74.368 0-102.656L398.912 513.152z" fill="#16181c" p-id="6081"></path></svg></div>
        <div class="header_title">Confirm Payment</div>
        <div></div>
      </div>
      <div class="page_width scoller grid_content" style="padding-bottom:60px">
        <!-- <div class="checkout_product">
          <div class="product_group flex">
            <div class="product_img">
              <img :src="product.image" alt="">
            </div>
            <div class="product_msg">
              <div class="product_title"> {{ product.detailStr.name }} </div>
            </div>
          </div>
          <div class="product_count flex_end">X {{ goodsNum }}</div>
          <div class="product_price flex_end" >${{ product.amount}} <div class="product_orgPrice" v-if="product.showAmount!=product.amount">${{ product.showAmount }}</div></div>
          <div class="product_total flex_sb">
            <div class="total_title">Total</div>
            <div class="total_price">${{ total }}</div>
          </div>
        </div> -->
        <div class="pay_before">
          <div class="before_title flex">
            <div class="title_icon flex_center"><svg t="1729498015899" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3976" width="16" height="16"><path d="M427.8 387.3H264.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h163.2c8.8 0 16 7.2 16 16s-7.2 16-16 16zM501.8 583.6H264.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h237.2c8.8 0 16 7.2 16 16s-7.2 16-16 16zM614.8 779.9H264.6c-8.8 0-16-7.2-16-16s7.2-16 16-16h350.2c8.8 0 16 7.2 16 16s-7.2 16-16 16zM688.2 486.2c-8.8 0-17.3-2.7-24.5-7.9l-80.6-58c-18.8-13.5-23.1-39.8-9.5-58.5l164-227.9c7.9-10.9 20.6-17.4 34.1-17.4 8.8 0 17.3 2.7 24.5 7.9l80.6 58c18.8 13.5 23.1 39.8 9.5 58.5l-164 227.9c-7.9 10.9-20.6 17.4-34.1 17.4z m83.5-337.7c-3.2 0-6.2 1.5-8.1 4.1l-164 227.9c-3.2 4.4-2.2 10.7 2.3 13.9l80.6 58c2.2 1.5 4.3 1.9 5.8 1.9 3.2 0 6.2-1.5 8.1-4.1l164-227.9c3.2-4.4 2.2-10.7-2.3-13.9l-80.6-58c-2.2-1.6-4.4-1.9-5.8-1.9z" fill="#c1c1c1" p-id="3977"></path><path d="M565 548.2c-3.3 0-6.6-1-9.3-3-4.6-3.3-7.2-8.9-6.6-14.6L562.9 390c0.6-5.7 4.1-10.6 9.3-13 5.2-2.4 11.3-1.8 15.9 1.6L703 460.9c4.6 3.3 7.2 8.9 6.6 14.6-0.6 5.7-4.1 10.6-9.3 13l-128.7 58.4c-2.1 0.9-4.4 1.3-6.6 1.3z m27-127.5l-8.4 85.5 78.2-35.5-69.8-50zM830.1 294.5c-3.2 0-6.5-1-9.3-3l-107.5-77.3c-7.2-5.2-8.8-15.2-3.6-22.3 5.2-7.2 15.2-8.8 22.3-3.6l107.5 77.3c7.2 5.2 8.8 15.2 3.6 22.3-3.2 4.3-8.1 6.6-13 6.6z" fill="#c1c1c1" p-id="3978"></path><path d="M756.1 550.3V864c0 6.5-5.3 11.7-11.7 11.7h-544c-6.5 0-11.7-5.3-11.7-11.7V192c0-6.5 5.3-11.7 11.7-11.7h426c8.8 0 16-7.2 16-16s-7.2-16-16-16h-426c-24.2 0-43.7 19.6-43.7 43.7v672c0 24.2 19.6 43.7 43.7 43.7h544c24.2 0 43.7-19.6 43.7-43.7V550.3c0-8.8-7.2-16-16-16s-16 7.1-16 16z" fill="#c1c1c1" p-id="3979"></path></svg></div>
            <div class="title_text ">Filling in information</div>
          </div>
          <el-form label-position="top" label-width="80px" :model="payForm" ref="ruleForm" :rules="rules" >
            <el-form-item label="Country" prop="country">
              <el-input v-model="payForm.country" @focus="showPicker">
                <i slot="suffix" class="suffix_icon flex_center" :class="[pickerVisible?'suffix_icon_rotate':'']"><svg t="1729567486803" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2277" width="16" height="16"><path d="M830.24 340.688l11.328 11.312a16 16 0 0 1 0 22.624L530.448 685.76a16 16 0 0 1-22.64 0L196.688 374.624a16 16 0 0 1 0-22.624l11.312-11.312a16 16 0 0 1 22.624 0l288.496 288.496 288.512-288.496a16 16 0 0 1 22.624 0z" fill="#161619" p-id="2278"></path></svg></i>
              </el-input>
            </el-form-item> 
            <el-form-item label="State" prop="state">
              <el-input v-model="payForm.state" @focus="statePickerVisible=true">
                <i slot="suffix" class="suffix_icon flex_center" :class="[statePickerVisible?'suffix_icon_rotate':'']"><svg t="1729567486803" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2277" width="16" height="16"><path d="M830.24 340.688l11.328 11.312a16 16 0 0 1 0 22.624L530.448 685.76a16 16 0 0 1-22.64 0L196.688 374.624a16 16 0 0 1 0-22.624l11.312-11.312a16 16 0 0 1 22.624 0l288.496 288.496 288.512-288.496a16 16 0 0 1 22.624 0z" fill="#161619" p-id="2278"></path></svg></i>
              </el-input>
            </el-form-item> 
            <el-form-item label="City" prop="city">
              <el-input v-model="payForm.city"></el-input>
            </el-form-item>
            <el-form-item label="Address " prop="address">
              <el-input v-model="payForm.address"></el-input>
            </el-form-item>
            <el-form-item label="ZIP" prop="zip">
              <el-input v-model="payForm.zip"></el-input>
            </el-form-item>
            <el-form-item label="Phone" prop="phone">
              <el-input v-model="payForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="E-mail" prop="email">
              <el-input v-model="payForm.email"></el-input>
            </el-form-item>
         
          
          </el-form>
        </div>
      
        <div class="pay_list">
          <div class="pay_list_title">Card information</div>
          <div class="pay_list_mod">
            <!-- <div class="pay_mod_top flex_start_center">
              <div class="mod_agree"></div>
              <div class="mod_img">
                <img src="https://bigplayers-global.oss-accelerate.aliyuncs.com/000002FMfhF2vE2Nb0000UBEWLOG000000Fy9?OSSAccessKeyId=LTAI5t7YSjpc7hnHgZvryrpf&Expires=1893427199&Signature=ILh2aFpJyyKk7pj0ZxXKJZFq6s8%3D" alt="">
              </div>
            </div> -->
            <div class="form_content">
              <el-form label-position="left" label-width="90px" :model="payForm" ref="ruleForm2" :rules="rules" >
                <el-form-item label="FirstName:" prop="firstName">
                  <el-input v-model="payForm.firstName" placeholder="please enter your firstName"></el-input>
                </el-form-item>
                <el-form-item label="LastName:" prop="lastName">
                  <el-input v-model="payForm.lastName" placeholder="Enter the full lastName"></el-input>
                </el-form-item>
                <el-form-item label="CardNumber:" prop="cardNo">
                  <el-input v-model="payForm.cardNo" placeholder="Please enter the Card Number"></el-input>
                </el-form-item>
                <el-form-item label="CVV:" prop="cvv">
                  <el-input v-model="payForm.cvv" placeholder="Please enter the cvv"></el-input>
                </el-form-item>
                <el-form-item label="Year" prop="year">
                    <el-input v-model="payForm.year" @focus="yearPickerVisible=true">
                        <i slot="suffix" class="suffix_icon flex_center" :class="[pickerVisible?'suffix_icon_rotate':'']"><svg t="1729567486803" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2277" width="16" height="16"><path d="M830.24 340.688l11.328 11.312a16 16 0 0 1 0 22.624L530.448 685.76a16 16 0 0 1-22.64 0L196.688 374.624a16 16 0 0 1 0-22.624l11.312-11.312a16 16 0 0 1 22.624 0l288.496 288.496 288.512-288.496a16 16 0 0 1 22.624 0z" fill="#161619" p-id="2278"></path></svg></i>
                    </el-input>
                </el-form-item> 
                <el-form-item label="Month" prop="month">
                    <el-input v-model="payForm.month" @focus="monthPickerVisible=true">
                        <i slot="suffix" class="suffix_icon flex_center" :class="[pickerVisible?'suffix_icon_rotate':'']"><svg t="1729567486803" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2277" width="16" height="16"><path d="M830.24 340.688l11.328 11.312a16 16 0 0 1 0 22.624L530.448 685.76a16 16 0 0 1-22.64 0L196.688 374.624a16 16 0 0 1 0-22.624l11.312-11.312a16 16 0 0 1 22.624 0l288.496 288.496 288.512-288.496a16 16 0 0 1 22.624 0z" fill="#161619" p-id="2278"></path></svg></i>
                    </el-input>
                </el-form-item> 
              </el-form>
              
              <!-- <div class="radio_content flex">
                <div class="radio_label" @click="radio_select=!radio_select">
                  <img v-if="radio_select" src="https://global-cdn.bigplayers.com/m/img/yellow/select.png" alt="">
                  <img v-else src="https://global-cdn.bigplayers.com/m/img/yellow/no-select.png" alt="">
                 
                </div>
                <div class="radio_msg">Remember the information above</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_group flex_center">
        <div class="btn_msg">
          <!-- <div class="msg_text">The amount actually paid:</div>
          <div class="msg_price">${{ total }}</div> -->
        </div>
        <div class="group_btn">
          <div class="btn def_btn" @click="pay()">Pay Now</div>
        </div>
      </div>
      <VuePicker :data="countryOptions" :showToolbar="true"  @confirm="confirm" :visible.sync="pickerVisible" cancelText="Cancel" confirmText="Confirm" />
      <VuePicker :data="stateOptions" :showToolbar="true"  @confirm="stateConfirm" :visible.sync="statePickerVisible" cancelText="Cancel" confirmText="Confirm" />
      <VuePicker :data="yearOptions" :showToolbar="true"  @confirm="yearConfirm" :visible.sync="yearPickerVisible" cancelText="Cancel" confirmText="Confirm" />
      <VuePicker :data="monthOptions" :showToolbar="true"  @confirm="monthConfirm" :visible.sync="monthPickerVisible" cancelText="Cancel" confirmText="Confirm" />
      <remote-js src="https://pv.sohu.com/cityjson?ie=utf-8"></remote-js>
    </div>
  </template>
  
  <script>
  import VuePicker from 'vue-pickers';
  import global from '../../utils/global';
  import { divide } from '../../utils/tool';
  export default {
    components:{
      VuePicker,
      'remote-js': {
        render (createElement) {
            return createElement('script', { attrs: { type: 'text/javascript', src: this.src } });
        },
        props: {
            src: { type: String, required: true },
        },
      }
    
    },
    data(){
      return {
        radio_select:false,
        pickerVisible:false,
        statePickerVisible:false,
        yearPickerVisible:false,
        monthPickerVisible:false,
        payForm:{
            country:'',
            countryCode:'',
            state:'',
            stateCode:'',
            city:'',
            address:'',
            zip:'',
            email:'',
            firstName:'',
            lastName:'',
            year:'',
            month:'',
            cvv:'',
            ip:'',
            cardNo:'',
            orderId:'',
        },
        rules:{
          country: [ { required: true, message: 'Please select a country', trigger: 'change' }],
          state: [ { required: false, message: 'Please select a state', trigger: 'change' }],
          city: [  { required: true, message: 'Enter city', trigger: 'blur' } ],
          address : [  { required: true, message: 'Please enter the address', trigger: 'blur' } ],
          zip: [  { required: true, message: 'Enter zip', trigger: 'blur' } ],
          email: [  { required: true, message: 'Please enter your e-mail', trigger: 'blur' } ],
          phone:[  { required: true, message: 'Please enter your phone', trigger: 'blur' } ],
          firstName: [  { required: true, message: 'Enter the full firstName.', trigger: 'blur' } ],
          lastName: [  { required: true, message: 'Enter the full lastName.', trigger: 'blur' } ],
          year: [  { required: true, message: 'Enter the full year.', trigger: 'change' } ],
          month: [  { required: true, message: 'Enter the full month.', trigger: 'change' } ],
          cvv: [  { required: true, message: 'Enter the full cvv.', trigger: 'blur' } ],
          cardNo: [  { required: true, message: 'Enter the full card number.', trigger: 'blur' }, { min: 13, max: 19, message: 'Length of 13 to 19', trigger: 'change' } ],
        },
        countryOptions: [ ],
        stateOptions:[],
        yearOptions:[[
            {value:'2024',label:'2024'},{value:'2025',label:'2025'},{value:'2026',label:'2026'},{value:'2027',label:'2027'},{value:'2028',label:'2028'},
            {value:'2029',label:'2029'},{value:'2030',label:'2030'},{value:'2031',label:'2031'},{value:'2032',label:'2032'},{value:'2033',label:'2033'},
        ]],
        monthOptions:[[
            {value:'01',label:'01'},{value:'02',label:'02'},{value:'03',label:'03'},{value:'04',label:'04'},{value:'05',label:'05'},
            {value:'06',label:'06'},{value:'07',label:'07'},{value:'08',label:'08'},{value:'09',label:'09'},{value:'10',label:'10'},
            {value:'11',label:'11'},{value:'12',label:'12'}
        ]],
        result:{},
        hasResult:false,
        jsonObject:{},
        product:{},
        goodsId:'',
        goodsNum:1,
  
      
      }
  
    },
    created(){
        this.getIp()
        this.getCountryOptions()
        let orderId = this.$route.query.orderId
      
        this.payForm.orderId = orderId
        console.log('orderId',orderId)
    },
    methods:{
        getCountryOptions(){
            let data = global.data.country
            data.map(v=>{
                v.value = v.iso_code_2
                v.label = v.name
                return v
            })
            this.countryOptions.push(data)
        },
      toBack(){
        this.$router.back()
      },
      confirm(e){
        // let result = JSON.stringify(e)
        console.log('confirm',e,e[0],e[0].label)
        this.payForm.country = e[0].label
        this.payForm.countryCode = e[0].iso_code_2
        this.payForm.state = ''
        this.payForm.stateCode = ''
        this.stateOptions = []
        let zone =e[0].zone
        zone.map(v=>{
            v.label = v.name
            v.value = v.code
            return v
        })
        this.stateOptions.push(zone)
      },
      stateConfirm(e){
        console.log('confirm',e,e[0],e[0].label)
        this.payForm.state = e[0].label
        this.payForm.stateCode = e[0].code
      },
      yearConfirm(e){
        this.payForm.year = e[0].label
      },
      monthConfirm(e){
        this.payForm.month = e[0].label
      },
      showPicker(){
        this.pickerVisible = true
       
      },
    
   
      getProduct(){
        let goodsId = this.goodsId
        let language = this.lang.dictValue
        let _this = this
        if(!language||language==undefined){   
            setTimeout(()=>{
                  _this.getProduct() 
            },1000)
            return
        }
        this.$axiosApi.getGoodsDetail({goodsId,language}).then(res=>{
            if(res.code == 200){
              let product=res.data
              let detailStr = JSON.parse(res.data.detailStr)
              let images = JSON.parse(res.data.images)
              product.detailStr = detailStr
              product.images = images
              this.total =divide(Number( this.goodsNum)*Number(product.amount)) 
              product.amount = divide(product.amount)
              product.showAmount = divide(product.showAmount)
              this.goodsId = res.data.id
              this.product = product
              
              console.log('preTradeDeliveryCollectionTemplateNeeded',detailStr.preTradeDeliveryCollectionTemplateNeeded) 
              if(detailStr.preTradeDeliveryCollectionTemplateNeeded){
             
                this.getDate()
              }
                console.log('res',detailStr)
            }
        })
      },
      submitForm(formUser) {
        return new Promise((resolve, reject) => {
            this.$refs[formUser].validate((valid) => {
            if (valid) {
                resolve()
            } else {
                reject(new Error('erroe'))
            }
            })
        })
        },
      pay(){
        let _this = this
        Promise.all([this.submitForm('ruleForm'), this.submitForm('ruleForm2')])
        .then(() => {
          //验证成功后在此处发请求
            let payForm = _this.payForm
            let orderNo = payForm.orderId
            let cardNo=payForm.cardNo
            let cardSecurityCode = payForm.cvv
            let cardExpireMonth = payForm.month
            let cardExpireYear = payForm.year
            let billFirstName = payForm.firstName
            let billLastName = payForm.lastName
            // let ip = payForm.ip
            // let ip = '45.88.42.177'
            let ip = '192.168.1.1'
            let billAddress = payForm.address
            let billCity = payForm.city
            let billState = payForm.stateCode?payForm.stateCode:payForm.countryCode
            let billCountry = payForm.countryCode
            let billZip = payForm.zip
            let billPhone = payForm.phone
            let email = payForm.email
            let shipFirstName = payForm.firstName
            let shipLastName = payForm.lastName
            let shipAddress = payForm.address
            let shipCity = payForm.city
            let shipState = payForm.stateCode?payForm.stateCode:payForm.countryCode
            let shipCountry = payForm.countryCode
            let shipZip = payForm.zip
            let shipPhone = payForm.phone
            
            _this.$axiosApi.pay({orderNo,cardNo,cardSecurityCode,cardExpireMonth,cardExpireYear,billFirstName,billLastName,ip,billAddress,billCity,billState,billCountry,billZip,billPhone,email,shipFirstName,shipLastName,shipAddress,shipCity,shipState,shipCountry,shipZip,shipPhone}).then(res=>{
                if(res.code == 200){
                    if(res.data.respCode == 10000){
                        console.log('respCode 10000')
                        _this.$message.success(res.data.respMessage)
                        setTimeout(()=>{
                            _this.$router.push('/order')
                        },1500)
                    }
                    else if(res.data.respCode != 10000){
                        console.log('respCode !10000')
                        _this.$message.error(res.data.respMessage)
                        if(res.data.respCode == 10002){
                            if (res.data.payUrl) {
                                console.log('res.data.payUrl',res.data.payUrl)
                                window.open(res.data.payUrl)
                                return
                                // location.href = res.data.data.payUrl
                                window.localStorage.removeItem('callbackHTML')
                                window.localStorage.setItem('callbackHTML', res.data.payUrl)
                                // 读取本地保存的html数据，使用新窗口打开
                                var newWin = window.open(res.data.payUrl)
                                newWin.document.write(localStorage.getItem('callbackHTML'))
                                // 关闭输出流
                                newWin.document.close()
                            }
                        }
                    }
                    
                    
                   
                }
            })
        })
        .catch(() => {
        //   this.$message.error('验证失败')
        })

        return
       
        this.$axiosApi.createOrder({goodsId,goodsNum,jsonObject}).then(res=>{
          if(res.code == 200){
            this.$message.success(res.msg)
            setTimeout(()=>{
              _this.$router.push('/order')
            },1200)
          }
        })
      },
      getIp () {
        try {
            return this.payForm.ip = returnCitySN["cip"]
        } catch (err) {
            setTimeout(() => {
            this.getIp()
            }, 1000)
        }
        },
    },
    computed:{
      lang(){return this.$store.state.lang}
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .checkout{
    background: #f6f6f8 !important;
    .suffix_icon{
        height: 100%;
        // transform: rotate(-90deg);
        translate: transform 0.35s ease-in-out;
      }
      .suffix_icon_rotate{
        transform: rotate(180deg)
      }
    .checkout_product{
      padding:13px;
      border-radius: 10px;
      background:#fff;
      margin-top:16px;
      margin-bottom:8px;
      .product_group{
        .product_img{
          width: 115px;
          height: 115px;
          overflow: hidden;
  
        }
        .product_msg{
          margin-left:13px;
          text-align: left;
          font-size: 16px;
          .product_title{
            color:#222425;
            font-weight: bold;
            margin-bottom:10px;
          }
          .product_type{
            color:#909194;
            font-size: 14px;
          }
        }
      }
      .product_count{
        color:#909194;
      }
      .product_price{
        color:#222425;
        align-items: baseline;
        .product_orgPrice{
          padding-left:6px;
          font-size: 12px;
          color:#909194;
          text-decoration: line-through;
        }
      }
      .product_total{
        padding-top:16px;
        margin-top:10px;
        border-top: 1px solid #ededf2;
        font-size:14px;
        color:#161619;
        .total_price{
          color:#f77e1a;
        }
      }
    }
    .pay_before{
      border-radius: 10px;
      background:#fff;
      margin-top:16px;
      padding:13px;
      text-align: left;
      .before_title{
        padding:13px 0;
        .title_text{
          color:#222425;
          margin-left:4px;
        }
      }
      /deep/.el-form-item {
        margin-bottom:10px;
      }
      /deep/.el-form-item__label{
        font-size: 13px;
        color:#161619;
        height: 34px;
        padding:0;
      }
     
    }
    .pay_list{
      margin-top:16px;
      margin-bottom:64px;
      .pay_list_title{
        font-size: 14px;
        color:#161619;
        font-weight: bold;
        margin-bottom:12px;
        text-align: left;
      }
      .pay_list_mod{
        border-radius: 6px;
        background:#fff;
        border:1px solid #f77e1a;
        .pay_mod_top{
          padding:10px;
          .mod_agree{
            width:20px;
            height: 20px;
            margin-right:14px;
            background:url(https://global-cdn.bigplayers.com/m/img/yellow/select.png);
            background-size: 100% 100%;
          }
          .mod_img{
            height: 32px;
            >img{
              height: 100%;
              width: auto;
            }
          }
        }
        .form_content{
          padding: 15px 12px;
          .radio_content{
            color:#222425;
            .radio_label{
              width:20px;
              height: 20px;
              margin-right:14px;
            }
          }
        }
      }
    }
    .bottom_group{
      height: 54px;
      padding:0 17px;
      position: absolute;
      bottom:0;
      left:0;
      right:0;
      background:#fff;
      z-index: 9;
      .btn_msg{
        flex:1;
        text-align: right;
        padding-right:14px;
        .msg_text{
          color:#909194;
          font-size: 14px;
        }
        .msg_price{
          color:#f77e1a;
          font-size: 14px;
          font-weight: bold;
        }
      }
      .group_btn{
        width:120px;
      }
    }
    /deep/.el-input__inner{
      font-size: 14px;
      &:focus{
        border:1px solid #DCDFE6;
      }
    }
    .pickerbox{
      position: absolute;
      height: 100%;
      width: 100%;
      /deep/.header{
        padding:0;
        background:#fae44c;
      }
      /deep/.left{
        .btn{
          color:#909194;
        }
        
      }
      /deep/.right{
        .btn{
          color:#f77e1a;
        }
        
      }
    }
    
    /deep/.vue-picker {
        border-radius: 20px 20px 0 0;
        overflow: hidden;
   
    }
    
  }
  
  </style>